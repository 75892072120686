
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import Toast from "@/common/ts/Toast.js";
import { AxiosRequestConfig } from "node_modules/axios";
import Util from "@/common/ts/Util";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const submitButton = ref<HTMLButtonElement | null>(null);
    const mobile = ref(""); //以后再优化
    const gbnTime = ref(0);

    const showTime = () => {
      gbnTime.value++;
      if (gbnTime.value <= 60) {
        setTimeout(() => {
          showTime();
        }, 1000);
      } else {
        gbnTime.value = 0;
      }
    };

    //Create form validation object
    const login = Yup.object().shape({
      mobile: Yup.string().required("请输入手机号码"),
      auth_code: Yup.string().required("请输入验证码")
    });

    //Form submit function
    const onSubmitLogin = (values) => {
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      if (submitButton.value) {
        // eslint-disable-next-line
        submitButton.value!.disabled = true;
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      store
        .dispatch(Actions.LOGIN_AUTH_CODE, values)
        .then(() => {
          router.push({ name: "dashboard" });
        })
        .catch(() => {
          Toast.warning(store.getters.getErrors[0]);
        })
        .finally(() => {
          //Deactivate indicator
          submitButton.value?.removeAttribute("data-kt-indicator");
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        });
    };

    const handleAuthCodeSend = () => {
      if (gbnTime.value > 0) {
        return;
      }

      if (mobile.value) {
        if (!Util.isMobile(mobile.value)) {
          Toast.warning("手机号码格式不正确");
          return;
        }
      } else {
        Toast.warning("请输入手机号码");
        return;
      }

      ApiService.post("MemberAuth/AuthCodeSend", {
        mobile: mobile.value,
        auth_type: "02"
      } as AxiosRequestConfig)
        .then(({ data }) => {
          if (data.success == true) {
            Toast.success(data.message);
            showTime();
          } else {
            Toast.warning(data.message);
          }
        })
        .catch((error) => {
          Toast.error(error.message);
        });
    };

    return {
      onSubmitLogin,
      login,
      submitButton,
      handleAuthCodeSend,
      mobile,
      gbnTime
    };
  }
});
